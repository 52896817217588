<template>
  <v-container>
    <v-tabs centered v-model="tab" >
      <v-tab :stations="stations">Add Trip</v-tab>
      <v-tab>Trip Log</v-tab>
    </v-tabs>
    <v-tabs-items :touchless="true" v-model="tab">
      <v-tab-item>
        <AddTrip  :trips.sync="trips" :stations="stations"/>
      </v-tab-item>
      <v-tab-item>
        <TripLog :trips.sync="trips"/>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import AddTrip from "../components/AddTrip";
import TripLog from "../components/TripLog";

export default {
  name: "Trips",
  components: {TripLog, AddTrip},
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    })
  },
  data: function(){
    return {
      tab: null,
      trips: [],
      stations: []
    }
  },
  created: function(){
    axios.get(this.$baseURL + "/userJourneys", {
      headers : {
        Authorization: "Token " + this.currentUser['token']
      }
    }).then(response =>{
      if (response.data !== null) {
        this.trips = response.data
      }
    })

    axios.get(this.$baseURL + "/stations").then(response =>{
      if (response.data !== null) {
        this.stations = response.data['results']
      }
    })
  },
  methods: {
  }
}
</script>

<style scoped>

</style>