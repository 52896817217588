var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-row',[_c('v-col',{attrs:{"md":"8","cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" All Trips "),_c('v-spacer')],1),_c('v-data-table',{attrs:{"items":_vm.trips,"headers":_vm.headers,"items-per-page":15,"item-class":_vm.rowClass},scopedSlots:_vm._u([{key:"item.departure_station",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.departure_station(item))+" ")]}},{key:"item.departure_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.departure_time(item))+" ")]}},{key:"item.arrival_station",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.arrival_station(item))+" ")]}},{key:"item.arrival_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.arrival_time(item))+" ")]}},{key:"item.trip_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.trip_time(item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticStyle:{"margin-right":"5px"},attrs:{"color":"success"},on:{"click":function($event){return _vm.viewTrip(item['id'])}}},[_vm._v("View")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteTrip(item['id'])}}},[_vm._v("Delete")])],1)]}}])})],1)],1),_c('v-col',{staticClass:"d-flex flex-column",attrs:{"md":"4","cols":"12"}},[_c('v-row',{staticStyle:{"min-height":"40vh"}},[_c('l-map',{staticStyle:{"z-index":"0"},attrs:{"center":_vm.mapCenter,"zoom":_vm.zoom}},[_c('l-tile-layer',{attrs:{"url":_vm.mapURL}}),_vm._l((_vm.coords),function(latLng,index){return _c('l-polyline',{attrs:{"lat-lngs":latLng,"radius":4,"color":"red"}})})],2)],1)],1)],1),_c('ViewTripModal',{attrs:{"dialog-state":_vm.dialogState,"tripID":_vm.modalTripID,"trips":_vm.internalTrips},on:{"update:dialogState":function($event){_vm.dialogState=$event},"update:dialog-state":function($event){_vm.dialogState=$event}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }