<template>
  <v-dialog v-model="internalDialogState" width="1000" style="z-index: 30">
    <v-card>
      <v-card-title class="headline">
        Trip
      </v-card-title>
      <v-card-text>
        {{trip}}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="internalDialogState = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ViewTripModal",
  props: ['dialogState', 'tripID', 'trips'],
  data: function(){
    return {

    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    internalDialogState: {
      get: function () {
        return this.dialogState
      },
      set: function (val) {
        this.$emit('update:dialogState', val)
      }
    },
    trip: function(){
      return this.trips.filter(trip => {
        return trip['id'] === this.tripID
      })[0]
    }
  }
}
</script>

<style scoped>

</style>