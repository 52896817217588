<template>
  <div>
    <div>
     <v-row>
       <v-col md="8" cols="12">
         <v-card>
           <v-card-title>
             All Trips
             <v-spacer></v-spacer>
           </v-card-title>
           <v-data-table :items="trips" :headers="headers" :items-per-page="15" :item-class="rowClass">
             <template #item.departure_station="{ item }">
               {{ departure_station(item) }}
             </template>
             <template #item.departure_time="{ item }">
               {{ departure_time(item) }}
             </template>
             <template #item.arrival_station="{ item }">
               {{ arrival_station(item) }}
             </template>
             <template #item.arrival_time="{ item }">
               {{ arrival_time(item) }}
             </template>
             <template #item.trip_time="{ item }">
               {{ trip_time(item) }}
             </template>
             <template #item.actions="{ item }">
               <div class="d-flex">
                 <v-btn color="success" v-on:click="viewTrip(item['id'])" style="margin-right: 5px">View</v-btn>
                 <v-btn color="error" v-on:click="deleteTrip(item['id'])" >Delete</v-btn>
               </div>
             </template>
           </v-data-table>
         </v-card>
       </v-col>
       <v-col md="4" cols="12" class="d-flex flex-column">
         <v-row style="min-height: 40vh;">
           <l-map :center="mapCenter" :zoom="zoom" style="z-index: 0;">
             <l-tile-layer :url="mapURL"></l-tile-layer>
             <l-polyline v-for="(latLng, index) in coords" :lat-lngs="latLng" :radius="4" color="red">
             </l-polyline>
           </l-map>
         </v-row>
       </v-col>
     </v-row>
      <ViewTripModal :dialog-state.sync="dialogState" :tripID="modalTripID" :trips="internalTrips"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "axios";
import {
    LMap,
    LTileLayer,
    LPolyline
} from "vue2-leaflet";
import ViewTripModal from "./ViewTripModal";
import moment from "moment-timezone"

export default {
  name: "TripLog",
  components: {
    ViewTripModal,
    LMap,
    LPolyline,
    LTileLayer
  },
  props: ['trips'],
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    internalTrips: {
      get: function(){
        return this.trips
      },
      set: function(val){
        console.log("test")
        this.$emit('update:trips', val)
      }
    },
  },
  watch: {
    internalTrips: function(){
      this.updateCoords()
    }
  },
  data: function(){
    return {
      modalTripID: "",
      dialogState: false,

      coords: {},
      headers: [
        {
          text: 'Headsign',
          value: 'headsign'
        },
        {
          text: 'Operator',
          value: 'operator_id'
        },
        {
          text: 'Departure Station',
          value: 'departure_station'
        },
        {
          text: 'Departure Time',
          value: 'departure_time'
        },
        {
          text: 'Arrival Station',
          value: 'arrival_station'
        },
        {
          text: 'Arrival Time',
          value: 'arrival_time'
        },
        {
          text: 'Time',
          value: 'trip_time',
          align: 'center'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          width: '1%'
        }
      ],
      mapCenter: L.latLng(45,0),
      zoom: 4,
      mapURL:'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
    }
  },
  created: function(){
    this.updateCoords()
  },
  methods: {
    departure_station: function (item){
      return item['stops'][0]['station']['name']
    },
    arrival_station: function (item){
      return item['stops'][(item['stops'].length-1)]['station']['name']
    },
    arrival_time: function(item){
      let stop = item['stops'][item['stops'].length - 1]
      return (moment.tz(stop['arrival_time'], 'HHmmss', stop['station']['time_zone'])).format('HH:mm:ss z')
    },
    departure_time: function(item){
      let stop = item['stops'][0]
      return (moment.tz(stop['departure_time'], 'HHmmss', stop['station']['time_zone'])).format('HH:mm:ss z')
    },
    trip_time: function(item){
      let startTime = moment(item['stops'][0]['utc_departure_time'], 'HHmmss')
      let endTime =  moment(item['stops'][(item['stops'].length-1)]['utc_arrival_time'], 'HHmmss')

      return moment.utc(endTime.diff(startTime)).format("HH:mm");
    },
    rowClass: function(){
      return "singleRow"
    },
    viewTrip: function(id){
      this.modalTripID = id
      this.dialogState = true
    },
    deleteTrip: function(id){
      axios.delete(this.$baseURL + "/userJourneys/" + id, {
        headers : {
          Authorization: "Token " + this.currentUser['token']
        },
      }).then(response =>{
        this.internalTrips = [...this.internalTrips.filter(trip => {
          return trip['id'] !== id
        })]
      })
    },
    updateCoords: function(){
      this.coords = {}
      this.internalTrips.forEach((trip) => {
        this.getCoords(trip['id'])
      })
    },
    getCoords: function(userJourneyID){
      axios.get(this.$baseURL + "/plotRouteUser", {
        headers : {
          Authorization: "Token " + this.currentUser['token']
        },
        params: {
          'user_journey_id': userJourneyID
        }
      }).then(response =>{
        if (response.data !== null) {

          let coords = response.data.map(item => {
            return [item['Latitude'], item['Longitude']]
          })

          this.$set(this.coords, userJourneyID, coords)
        }
      })
    },
    calcDistance: function (lat1, lon1, lat2, lon2){

      function toRad(Value)
      {
        return Value * Math.PI / 180;
      }

      const R = 3440; // nautical miles
      const dLat = toRad(lat2-lat1);
      const dLon = toRad(lon2-lon1);
      lat1 = toRad(lat1);
      lat2 = toRad(lat2);

      const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      return R * c;
    },
  }
}
</script>

<style>
.iconClass{
  background-color: red;
  width: auto !important;
  height: auto !important;
  border-radius: 8px;
  padding: 2px;
}

.singleRow{
  white-space: nowrap;
}
</style>
