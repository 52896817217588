<template>
  <div>
    <!-- Initial create page -->
    <div>
      <v-row>
        <v-col>
          <h3>Add Trip</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6" cols="12" style="border-right: 1px solid;">
          <v-form ref="form">
            <div style="text-align: center">
              <v-row class="sectionTitle" style="align-items: center">
                <v-divider></v-divider>
                  <h4>Journey Info</h4>
                <v-divider></v-divider>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field label="Headsign" v-on:change="updateJourneys" v-model="headsign" required :rules="requiredFieldRule"/>
                </v-col>
                <v-col>
                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto" style="z-index:20;">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="date" label="Picker in menu" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" required :rules="requiredFieldRule"></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false; $refs.menu.save('')">
                        Clear
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.menu.save(date)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select label="Operator (optional)" v-on:change="updateJourneys" :items="operators" v-model="operator"/>
                </v-col>
                <v-col>
                </v-col>
              </v-row>
              <v-row class="sectionTitle" style="align-items: center">
                <v-divider></v-divider>
                  <h4>Stops</h4>
                <v-divider></v-divider>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete v-model="departureStation" :items="departureStations" label="Departure Station" required :rules="requiredFieldRule"/>
                </v-col>
                <v-col>
                  <v-autocomplete v-model="departureTime" :items="departureTimes" no-filter label="Departure Times" item-text="text" item-value="value" required :rules="requiredFieldRule"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete v-model="arrivalStation" :items="arrivalStations" label="Arrival Station" required :rules="requiredFieldRule"/>
                </v-col>
                <v-col>
                  <v-text-field v-model="arrivalTime" label="Arrival Time" :readonly="true"></v-text-field>
                </v-col>
              </v-row>
              <div class="centerActionsContainer">
                <v-btn color="success" v-on:click="saveTrip()" :disabled="!validTripDefined">Save Trip</v-btn>
                <v-btn color="error" v-on:click="clearForm()">Reset</v-btn>
              </div>
            </div>
          </v-form>
        </v-col>
        <v-col md="6" cols="12" style="border-left: 1px solid;">
          <l-map :center="mapCenter" :zoom="zoom" style="z-index: 0;">
            <l-tile-layer :url="mapURL"></l-tile-layer>
            <l-polyline :lat-lngs="coords" :radius="4" color="red">
            </l-polyline>
          </l-map>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import {LMap, LPolyline, LTileLayer} from "vue2-leaflet";
import moment from "moment-timezone"

export default {
  name: "AddTrip",
  components: {
    LMap,
    LPolyline,
    LTileLayer
  },
  props: ['stations', 'trips'],
  data: function(){
    return {
      headsign: "",
      headsignTrips: "",
      operator: "",
      date: "",
      departureStation: "",
      departureTime: "",
      arrivalStation: "",
      menu: null,
      operators: ["SNCF", "Renfe", "DB"],
      requiredFieldRule: [
        v => !!v
      ],
      journeys: [],
      coords: [],
      mapCenter: L.latLng(45,0),
      zoom: 4,
      mapURL:'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
    }
  },
  watch: {
    date: function(){
      this.updateJourneys()
    },
    headsign: function(){
      this.updateJourneys()
    },
    operator_id: function(){
      this.updateJourneys()
    },
    selectedJourney: function(){
      this.getCoords()
    },
    departureTimes: function(val){
      if (val.length === 1){
        this.departureTime = val[0]['value']
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    internalTrips: {
      get: function(){
        return this.trips
      },
      set: function(val){
        this.$emit('update:trips', val)
      }
    },
    computedDateFormatted () {
      if (!this.date) return null

      const [year, month, day] = this.date.split('-')
      return `${year}${month}${day}`
    },
    getAllObtainedStops: function(){
      let stops = []
      for (let i = 0; i < this.journeys.length; i++){
        stops.push(...this.journeys[i]['stops'].filter(jStop => jStop['station'] !== null))
      }

      return stops
    },
    departureStations: function(){
      let stations = this.getAllObtainedStops.map(jStop => jStop['station']['name'])

      return [...new Set(stations)]
    },
    departureTimes: function(){
      let stopsForSelectedStation = this.getAllObtainedStops.filter(jStop => {
        return jStop['station']['name'] === this.departureStation
      })

      return stopsForSelectedStation.map(jStop => {
        return {
          "text": (moment.tz(jStop['departure_time'], 'HHmmss', jStop['station']['time_zone'])).format('HH:mm:ss z'),
          'value': jStop['departure_time']
        }
      })
    },
    possibleArrivalJourneysFilteredDownToFollowingStops: function(){
      let journeys = []
      for (let i = 0; i < this.journeys.length; i++){
        let journey = Object.assign({}, this.journeys[i])
        let stops = []
        let foundFirst = false
        for (let j = 0; j < journey['stops'].length; j++){
          let stop = journey['stops'][j]
          if (stop['departure_time'] === this.departureTime && stop['station']['name'] === this.departureStation && !foundFirst){
            foundFirst = true
            continue
          }

          if (foundFirst){
            stops.push(stop)
          }
        }
        journey['stops'] = stops
        journeys.push(journey)
      }
      return journeys
    },
    getAllPossibleArrivalStops: function(){
      let stops = []
      for (let i = 0; i < this.possibleArrivalJourneysFilteredDownToFollowingStops.length; i++){
        stops.push(...this.possibleArrivalJourneysFilteredDownToFollowingStops[i]['stops'])
      }

      return stops
    },
    arrivalStations: function(){
      let stations = this.getAllPossibleArrivalStops.map(jStop => jStop['station']['name'])

      return [...new Set(stations)]
    },
    selectedJourney: function(){
      for (let i = 0; i < this.journeys.length; i++){
        let journey = this.journeys[i]

        let foundFirst = false
        for (let j = 0; j < journey['stops'].length; j++){
          let jStop = journey['stops'][j]
          if (jStop['departure_time'] === this.departureTime && jStop['station']['name'] === this.departureStation && !foundFirst){
            foundFirst = true
          }

          if (foundFirst && jStop['station']['name'] === this.arrivalStation){
            return journey
          }
        }
      }
      return null
    },
    arrivalTime: {
      get: function() {
        let journey = this.selectedJourney
        if (journey === null){
          return ""
        }
        for (let i = 0; i < journey['stops'].length; i++) {
          let jStop = journey['stops'][i]
          if (jStop['station']['name'] === this.arrivalStation) {
            return (moment.tz(jStop['arrival_time'], 'HHmmss', jStop['station']['time_zone']).format('HH:mm:ss z'))
          }
        }
        return ""
      },
      set: function(){
        //
      }
    },
    selectedJourneyStops: function(){
      let journey = this.selectedJourney
      let stops = []
      let foundFirst = false
      for (let i = 0; i < journey['stops'].length; i++){
        let stop = journey['stops'][i]
        if (stop['departure_time'] === this.departureTime && stop['station']['name'] === this.departureStation && !foundFirst){
          foundFirst = true
        }

        if (foundFirst){
          stops.push(stop)
        }

        if (foundFirst && stop['station']['name'] === this.arrivalStation){
          break
        }
      }

      return stops
    },
    validTripDefined: function(){
      return this.arrivalTime !== ""
    }
  },
  methods: {
    updateJourneys: function(){
      let params = {
        'limit': 5,
      }

      if (this.date === '' || this.headsign === ''){
        this.journeys = []
        return
      }

      if (this.date !== ''){
        params['travel_date'] = this.computedDateFormatted
      }

      if (this.headsign !== ''){
        params['headsign'] = this.headsign
      }

      if (this.operator !== ''){
        params['operator_id'] = this.operator
      }

      axios.get(this.$baseURL + "/journeys", { params : params}).then(response =>{
        if (response.data !== null) {
          console.log('Data updated')
          let results = response.data['results']
          if (results === null){
            this.journeys = []
          }else{
            this.journeys = results
          }
        }
      })
    },
    clearForm: function(){
      this.headsign = ""
      this.operator = ""
      this.date = ""
      this.departureTime = ""
      this.departureStation = ""
      this.arrivalStation = ""
      this.$refs.form.reset()
    },
    getCoords: function(){
      this.coords = []
      if (!this.validTripDefined){
        return
      }
      axios.get(this.$baseURL + "/plotRoute", {
        params: {
          'journey_id': this.selectedJourney["id"],
          'dep_station_uic': this.selectedJourneyStops[0]['station_uic'],
          'arr_station_uic': this.selectedJourneyStops[this.selectedJourneyStops.length-1]['station_uic']
        }
      }).then(response => {
        if (response.data !== null){
          let coords = response.data.map(item => {
            return [item['Latitude'], item['Longitude']]
          })
          this.coords.push(...coords)
        }
      })
    },
    saveTrip: function(){
      let stops = []
      for (let i = 0; i < this.selectedJourneyStops.length; i++){
        let stop = this.selectedJourneyStops[i]
        stops.push({
          "station_uic": stop["station_uic"],
          "utc_arrival_time": stop["utc_arrival_time"],
          "utc_departure_time": stop["utc_departure_time"],
          "headsign": stop["headsign"],
          "arrival_time": stop["arrival_time"],
          "departure_time": stop["departure_time"],
          "station": stop["station"]
        })
      }
      let payload = {
        "name": this.selectedJourney["name"],
        "headsign": this.selectedJourney["headsign"],
        "operator_id": this.selectedJourney['operator_id'],
        "travel_date": this.selectedJourney['travel_date'],
        "journey_id": this.selectedJourney['id'],
        "stops": stops,
      }

      axios.put(this.$baseURL + "/userJourneys", payload, {
        headers : {
          Authorization: "Token " + this.currentUser['token']
        },
      }).then((response) => {
        alert("Added")

        payload["id"] = response['data']
        console.log(payload)
        console.log(response)
        this.internalTrips.push(payload)
        this.$emit('update:trips', this.internalTrips)

        this.clearForm()
      })
    }
  }
}
</script>

<style scoped>
  .centerActionsContainer .v-btn{
    margin: 5px;
  }

  .sectionTitle h4{
    margin-left:5%;
    margin-right:5%;
  }

  .sectionTitle .v-divider{
    margin-left:12px;
    margin-right:12px;
  }
</style>